import { GET } from './'

/**
 *
 * @param {object} params
 * @param {boolean} [params.projectExtraData] includes:
 * selectedSubjectIds
 * selectedSupplementIds
 * subscriptionConfig
 * consumedHours
 * plannedHours
 */
export const findSubscriptions = (params = {}) => {
  const { projectExtraData = false } = params
  let url = 'students/subscriptions'
  if (projectExtraData) url += '?projectExtraData=' + projectExtraData
  return GET({
    url
  })
}
