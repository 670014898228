import { POST, GET, PUT } from './index'

export const findAttendanceDTOs = ({
  date = null,
  visibleByDate = null,
  subscriptionId = ''
}) => {
  let url = 'students/attendances?'
  let params = []
  if (date) params.push(`date=${new Date(date).toDateString()}`)
  if (visibleByDate)
    params.push(`visibleByDate=${new Date(visibleByDate).toDateString()}`)
  if (subscriptionId) params.push(`subscriptionId=${subscriptionId}`)

  return GET({ url: url + params.join('&') })
}

export const saveTestAttendance = (data = {}) =>
  POST({
    url: 'students/attendances/test',
    data
  })

export const getNextAvailableTestAttendance = () =>
  GET({ url: 'students/attendances/next-available-test' })

/**
 *
 * @param {string} attendanceId
 * @param {{rating: number}} data
 */
export const saveRating = (attendanceId, data = {}) =>
  PUT({ url: 'students/attendances/rate-attendance/' + attendanceId, data })

export const getTestAttendanceCount = () =>
  GET({ url: 'students/attendances/test-attendance-count' })

export const getScheduledAttendances = () =>
  GET({ url: 'students/attendances/get-scheduled' })

export const saveAttendance = (data = {}) =>
  POST({ url: 'students/attendances', data })
export const updateAttendance = (id, data = {}) =>
  PUT({ url: 'students/attendances/' + id, data })
