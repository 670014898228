import * as React from 'react'

const SimpleClassfy = props => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width='100%'
    height='100%'
    viewBox='0 0 19 34'
    fill='none'
    {...props}
  >
    <path
      fill={props.color || '#34EDAE'}
      d='M15.895 22.244a17.6 17.6 0 0 0-3.294-2.017 54.14 54.14 0 0 1-3.25-1.66c-.945-.534-1.713-1.097-2.289-1.673-.487-.493-.62-1.042-.413-1.7.295-.645.871-1.07 1.757-1.304.96-.247 2.068-.205 3.28.124a8.14 8.14 0 0 1 1.83.74l2.645-3.62c-2.024-1.208-4.417-1.88-6.736-1.866-1.122 0-2.2.178-3.16.521a7.13 7.13 0 0 0-2.63 1.619c-.753.74-1.329 1.687-1.683 2.825-.473 1.51-.443 2.84.059 3.923.487 1.056 1.24 1.99 2.215 2.785a16.156 16.156 0 0 0 3.25 2.03 88.765 88.765 0 0 1 3.234 1.618c.946.508 1.714 1.03 2.275 1.564.473.453.606.947.428 1.55-.118.384-.34.686-.65.92a3.57 3.57 0 0 1-1.24.548c-.502.123-1.064.164-1.67.15-1.299-.04-2.717-.438-3.913-1.097a7.08 7.08 0 0 1-1.625-1.18L.091 29.597c.783.878 1.772 1.66 2.895 2.304a14.744 14.744 0 0 0 3.589 1.482c1.167.315 2.363.48 3.589.507a10.495 10.495 0 0 0 3.456-.48 7.834 7.834 0 0 0 2.866-1.646c.841-.754 1.447-1.742 1.816-2.949.458-1.454.414-2.757-.118-3.84a8.583 8.583 0 0 0-2.29-2.73ZM11.11 2.437c-.4 0-.769-.028-1.123-.096-.31-.055-.591-.151-.827-.288a1.403 1.403 0 0 1-.532-.508c-.133-.206-.207-.494-.222-.85L8.362.036H4.389v.686c0 1.564.532 2.84 1.58 3.813a4.7 4.7 0 0 0 1.418.906c.488.205.99.356 1.507.48.517.11 1.034.178 1.536.22.487.027.96.04 1.403.04h2.437V2.437h-3.16Z'
    />
  </svg>
)
export default SimpleClassfy
