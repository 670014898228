//@ts-check
import React, { useCallback, useEffect, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { hasPath, sortBy, take } from 'ramda'
import config from 'config'
import {
  getTestAttendanceCount,
  getScheduledAttendances
} from 'api/attendances'
import { getAvailableTestTeachers } from 'api/teachers'
import { shuffle } from 'utils'
import { useAuth } from 'context/AuthProvider'
import useSubjects from 'hooks/useSubjects'
import useCustomNavigate from 'hooks/useCustomNavigate'
import { H2, H4Light } from 'components/typography'
import Button from 'components/buttons/Button'
import VerticalTecherCard from 'components/cards/teacher/VerticalTecherCard'
import AttendanceHighlightCard from 'components/cards/highlight/AttendanceHighlightCard'
import HighlightCard from 'components/cards/highlight/HighlightCard'
import styles from './Home.module.css'

const EXAMPLE_LIMIT = 10
const useNewFeature = process.env.REACT_APP_USE_NEW_FEATURE || false

function Home() {
  const [state, setState] = useState({
    teachers: [],
    canBookAClass: false,
    scheduledAttendances: {}
  })
  const { student } = useAuth()
  const navigate = useCustomNavigate()
  const { subjectsById } = useSubjects()

  const { teachers, canBookAClass, scheduledAttendances } = state

  const repetitiveCalls = useCallback(async () => {
    try {
      const scheduledAttendances = await getScheduledAttendances()

      const {
        nextAttendance,
        prevAttendance,
        currentAttendance,
        GRACE_PERIOD_TO_ACCESS_CLASS
      } = scheduledAttendances

      const teachers = await getAvailableTestTeachers()
        .then(teachers =>
          useNewFeature
            ? teachers
            : teachers.filter(({ id }) => id === '5e9c4322f98d984daeac8c70')
        )
        .then(sortBy(teachers => !hasPath(['isOnline'], teachers)))
        .then(teachers => {
          const firstTeachers = teachers.filter(({ id }) =>
            [
              prevAttendance?.teacherId,
              currentAttendance?.teacherId,
              nextAttendance?.teacherId
            ].includes(id)
          )
          const otherTeachers = teachers.filter(
            ({ id }) =>
              ![
                prevAttendance?.teacherId,
                currentAttendance?.teacherId,
                nextAttendance?.teacherId
              ].includes(id)
          )
          return take(
            EXAMPLE_LIMIT,
            firstTeachers.concat(shuffle(otherTeachers))
          ).map(teacher => {
            const currentDate = new Date()

            const canAccessToCurrentClass =
              currentAttendance?.teacherId === teacher.id
            const canAccessToNextClass =
              nextAttendance?.teacherId === teacher.id &&
              new Date(nextAttendance.classStart) <= new Date()
            const canAccesToPrevClass =
              prevAttendance?.teacherId === teacher.id &&
              new Date(prevAttendance.classEnd).getTime() >=
                new Date(currentDate).setMinutes(
                  currentDate.getMinutes() - GRACE_PERIOD_TO_ACCESS_CLASS
                )
            const isAvailable = () => {
              if (canAccessToCurrentClass) return true
              if (canAccessToNextClass) return true
              if (canAccesToPrevClass) return true
              return false
            }
            const getAttendance = () => {
              if (canAccessToCurrentClass) return currentAttendance
              if (canAccessToNextClass) return nextAttendance
              if (canAccesToPrevClass) return prevAttendance
              return { teacherId: teacher.id }
            }
            teacher.attendance = getAttendance()
            teacher.available = useNewFeature ? isAvailable() : true

            return teacher
          })
        })

      // @ts-ignore
      setState(state => ({
        ...state,
        teachers,
        scheduledAttendances
      }))
    } catch (error) {
      console.error('Error in repetitiveCalls: ', error)
    }
  }, [])

  const fetchCommonData = useCallback(async () => {
    try {
      const testAttendanceCount = await getTestAttendanceCount()

      setState(state => ({
        ...state,
        canBookAClass: student.isNewUser && !testAttendanceCount
      }))
    } catch (error) {
      console.error('Error in fetchCommonData: ', error)
    }
  }, [student.isNewUser])

  useEffect(() => {
    fetchCommonData()
    repetitiveCalls()
    if (!useNewFeature) return
    const interval = setInterval(() => {
      repetitiveCalls()
    }, 10000)
    return () => {
      clearInterval(interval)
    }
  }, [fetchCommonData, repetitiveCalls])

  return (
    <section className={styles.container}>
      {useNewFeature && (
        <div className={styles.highlights}>
          <H2>Brain on & be cool 🚀</H2>
          <div
            style={{
              display: 'inline-flex',
              overflowX: 'auto',
              padding: 15,
              gap: 25
            }}
          >
            <ScheduledAttendanceCards
              scheduledAttendances={scheduledAttendances}
            />
            <HighlightCard
              title='Conoce Classfy'
              imgUrl='https://picsum.photos/500/500?random=4.webp&blur=4'
              buttonProps={{ label: 'Ver', href: config.videoTourURL }}
            />
          </div>
        </div>
      )}
      <div className={styles.teacherSection}>
        <div className={styles.teacherHeader}>
          <div>
            <H2>Tus profes 😎</H2>
            <H4Light className={styles.teacherSubheader}>
              Empieza tu clase
            </H4Light>
          </div>
          {canBookAClass && (
            <div className={styles.scheduleClass}>
              <Link to='/newly-registered'>
                <Button label='Agendar clase' size='medium' type='secondary' />
              </Link>
            </div>
          )}
        </div>
        <div className={styles.teacherList}>
          {teachers?.map((teacher, index) => {
            return (
              <VerticalTecherCard
                key={index}
                teacher={teacher}
                subjectsById={subjectsById}
                disabled={!teacher.available}
                onClick={() =>
                  navigate('/academy', {
                    state: teacher.attendance
                  })
                }
              />
            )
          })}
        </div>
      </div>
      {/* <div className={styles.subjectSection}>
        <H2>Tus asignaturas 🧠</H2>
        <div>TODO: tajetas de asignaturas </div>
      </div> */}
    </section>
  )
}

export default Home

function ScheduledAttendanceCards({ scheduledAttendances }) {
  const navigate = useNavigate()
  const {
    nextAttendance,
    prevAttendance,
    currentAttendance,
    testAttendances = []
  } = scheduledAttendances
  if (
    !nextAttendance &&
    !prevAttendance &&
    !currentAttendance &&
    !testAttendances.length
  )
    return null
  return (
    <>
      {!!testAttendances.length &&
        testAttendances.map((testAttendance, i) => {
          if (
            new Date(testAttendances[testAttendances.length - 1]?.classEnd) <=
            new Date()
          )
            return null
          return (
            <AttendanceHighlightCard
              title={
                i == 0 ? 'Tu 🥇 clase de prueba ' : 'Tu 🥈 clase de prueba '
              }
              date={testAttendance.classStart}
              subjectName={testAttendance.subjectName}
              buttonProps={{
                onClick: () =>
                  navigate('/academy', {
                    state: testAttendance
                  }),
                disabled: new Date(testAttendances[0]?.classStart) > new Date()
              }}
            />
          )
        })}
      {!!prevAttendance && (
        <AttendanceHighlightCard
          title={
            new Date(prevAttendance.classStart) <= new Date() &&
            new Date(prevAttendance.classEnd) >= new Date()
              ? 'Accede a tu clase de'
              : 'Entra en tu clase anterior de'
          }
          date={prevAttendance.classStart}
          subjectName={prevAttendance.subjectName}
          buttonProps={{
            onClick: () =>
              navigate('/academy', {
                state: prevAttendance
              })
          }}
        />
      )}
      {!!currentAttendance && (
        <AttendanceHighlightCard
          title='Accede a tu clase de'
          date={currentAttendance.classStart}
          subjectName={currentAttendance.subjectName}
          buttonProps={{
            onClick: () =>
              navigate('/academy', {
                state: currentAttendance
              })
          }}
        />
      )}
      {!!nextAttendance && (
        <AttendanceHighlightCard
          title={
            new Date(nextAttendance.classStart) <= new Date() &&
            new Date(nextAttendance.classEnd) >= new Date()
              ? 'Accede a tu clase de'
              : 'No te pierdas tu próxima clase de'
          }
          date={nextAttendance.classStart}
          subjectName={nextAttendance.subjectName}
          buttonProps={{
            onClick: () =>
              navigate('/academy', {
                state: nextAttendance
              }),
            disabled: new Date(nextAttendance.classStart) > new Date()
          }}
        />
      )}
    </>
  )
}
