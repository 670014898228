//@ts-check
import React from 'react'
import { dateToString } from 'utils/date'
import SimpleClassfy from 'assets/logos/main/SimpleClassfy'
import classfySignature from 'assets/images/serviceTerms/classfySignature.jpg'
import fakeSignature from 'assets/images/serviceTerms/fakeSignature.jpg'
import styles from './Conditions.module.css'

class Conditions extends React.Component {
  render() {
    const {
      showDecorations = true,
      studentSignature,
      tutorSignature,
      studentName,
      signedAt
    } = this.props
    return (
      <div className={styles.body}>
        {showDecorations && (
          <>
            <div className={styles.containerLogo}>
              <SimpleClassfy className={styles.classfyLogo} />
            </div>
            <h1 className={styles.mainTitle}>
              CONDICIONES GENERALES DE CONTRATACIÓN
            </h1>
          </>
        )}
        <h2 className={styles.floatingTitle}>
          {showDecorations
            ? 'Clases presenciales y online'
            : 'Condiciones de contratación'}
        </h2>

        <div className={showDecorations ? styles.mainContent : ''}>
          <ol>
            <li>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</li>
            <li>
              Nullam auctor justo et lectus lacinia, quis varius est fringilla.
            </li>
            <li>Proin at justo vel libero posuere tincidunt.</li>
            <li>Donec sit amet tortor nec justo bibendum mattis.</li>
            <li>Fusce non libero nec nulla dictum hendrerit.</li>
            <li>Vivamus volutpat arcu nec fringilla.</li>
            <li>Morbi facilisis nulla sed pharetra.</li>
            <li>
              Curabitur auctor justo id urna blandit, id fermentum dolor
              fermentum.
            </li>
            <li>Quisque et turpis a dui pharetra hendrerit.</li>
            <li>
              Nullam euismod eros in arcu tristique, et egestas nulla tempor.
            </li>
            <li>Sed et risus id eros sagittis cursus eu eu sem.</li>
            <li>
              Maecenas luctus felis sit amet eros varius, eu elementum nunc
              suscipit.
            </li>
            <li>Integer non libero sit amet justo convallis tristique.</li>
            <li>Ut at dolor sit amet elit interdum tincidunt.</li>
            <li>Vestibulum volutpat urna id bibendum ultrices.</li>
            <li>Proin euismod elit auctor nunc rhoncus feugiat.</li>
            <li>Quisque et turpis a dui pharetra hendrerit.</li>
            <li>
              Nullam euismod eros in arcu tristique, et egestas nulla tempor.
            </li>
            <li>Sed et risus id eros sagittis cursus eu eu sem.</li>
            <li>
              Maecenas luctus felis sit amet eros varius, eu elementum nunc
              suscipit.
            </li>
            <li>Sed et risus id eros sagittis cursus eu eu sem.</li>
            <li>
              Maecenas luctus felis sit amet eros varius, eu elementum nunc
              suscipit.
            </li>
            <li>Integer non libero sit amet justo convallis tristique.</li>
            <li>Ut at dolor sit amet elit interdum tincidunt.</li>
            <li>Vestibulum volutpat urna id bibendum ultrices.</li>
            <li>Proin euismod elit auctor nunc rhoncus feugiat.</li>
            <li>Quisque et turpis a dui pharetra hendrerit.</li>
            <li>
              Nullam euismod eros in arcu tristique, et egestas nulla tempor.
            </li>
          </ol>
        </div>
        {showDecorations && (
          <>
            <p className={styles.signature}>
              Firmado en MADRID con fecha
              <strong> {dateToString(signedAt)}</strong>
            </p>
            <div className={styles.signArea}>
              <div className={styles.signAreaSection}>
                <p>{studentName}</p>
                <img src={studentSignature || fakeSignature} />
              </div>
              <div className={styles.signAreaSection}>
                <p>El representante legal</p>
                <img src={tutorSignature || fakeSignature} />
              </div>
              <div className={styles.signAreaSection}>
                <p>Classfy</p>
                <img src={classfySignature} />
              </div>
            </div>

            <div className={styles.footer}>
              SERVICIOS ESTUDIANTILES INTEGRALES S.L.. con domicilio fiscal en
              Avenida de Bonn 12 Bajo 4
            </div>
          </>
        )}
      </div>
    )
  }
}

export default Conditions
