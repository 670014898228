export const SubjectTypes = {
  CIENCIAS: 'Ciencias',
  LETRAS: 'Letras'
}

export const SubjectSubtypes = {
  Ciencias: { CIENCIAS: 'Ciencias', CIENCIAS_SALUD: 'Ciencias de la salud' },
  Letras: {
    LETRAS: 'Letras',
    IDIOMAS: 'Idiomas',
    ARTES: 'Artes',
    OTRAS: 'Otras'
  }
}
export const SubjectLevels = {
  PRIMARIA: 'Primaria',
  SECUNDARIA: 'Secundaria',
  BACHILLERATO: 'Bachillerato',
  FP: 'FP',
  UNIVERSIDAD: 'Universidad',
  IDIOMAS: 'Idiomas',
  OTRAS: 'Otras'
}

export const International = { INTERNACIONAL: 'Internacional' }

export const SubjectSublevels = {
  Idiomas: { A1: 'A1', A2: 'A2', B1: 'B1', B2: 'B2', C1: 'C1', C2: 'C2' },
  Secundaria: International,
  Bachillerato: International
}
export const SubscriptionTypes = {
  pack: 'pack',
  rate: 'rate'
}
export const SubscriptionConfigTypes = {
  pack: 'pack',
  prepareMornings: 'prepareMornings',
  unlimited: 'unlimited',
  reinforces: 'reinforces',
  agora: 'agora',
  extraHours: 'extraHours',
  monthlyRate: 'monthlyRate'
}
export const SubscriptionTypeLabels = {
  pack: 'Pack',
  rate: 'Tarifa'
}

export const AttendanceStatusLabels = {
  planned: 'Planificada',
  imparted: 'Impartida',
  justified_absence: 'Falta jusificada',
  absence: 'Falta',
  canceled_by_app: 'Cancelado por el sistema'
}
export const AttendanceTypeLabels = {
  normal: 'Normal',
  recovery: 'Recuperación',
  free: 'Gratuita',
  promotion: 'Promoción'
}
export const AttendanceTypes = {
  NORMAL: 'normal',
  RECOVERY: 'recovery',
  FREE: 'free',
  PROMOTION: 'promotion',
  TEST: 'test'
}
export const AttendanceStatus = {
  PLANNED: 'planned',
  IMPARTED: 'imparted',
  JUSTIFIED_ABSENCE: 'justified_absence',
  ABSENCE: 'absence',
  CANCELED_BY_APP: 'canceled_by_app'
}
export const TicketStatusLabels = {
  open: 'Abierto',
  closed: 'Cerrado'
}
export const TicketTypes = {
  REGISTER: 'register',
  APP_HELP: 'appHelp',
  BUDGET: 'budget',
  QUESTION: 'question'
}

export const TicketTypeLabels = {
  register: 'Registro',
  appHelp: 'Ayuda',
  budget: 'Propuesta',
  question: 'Duda general'
}
export const AccessTypes = {
  STUDENT: 'student',
  TRIAL: 'trial',
  INTERNAL: 'internal'
}
export const AccessTypeLabels = {
  student: 'Alumno',
  trial: 'Prueba',
  internal: 'Prueba mundoestudiante'
}

export const SignedByTypes = {
  student: 'student',
  tutor: 'tutor'
}
export const SignedByLabels = {
  student: 'Estudiante',
  tutor: 'Tutor'
}
