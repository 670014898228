//@ts-check
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { assoc, assocPath, head, isEmpty } from 'ramda'
import Cross from 'assets/icons/Cross'
import Pencil from 'assets/icons/Pencil'
import {
  ONE_HOUR_IN_MILLISECONDS,
  formatDate,
  getCurrentMonth,
  getDaysInMonth,
  isPast,
  makeCustomDate,
  mergeHours,
  toTimeString
} from 'utils/date'
import config from 'config'
import { noop } from 'utils'
import { AttendanceStatus } from 'utils/constants'
import { updateAttendance } from 'api/attendances'
import { getAvailableHoursBy } from 'api/teachers'
import { useNotificationActions } from 'context/NotificationProvider'
import Button from 'components/buttons/Button'
import Select from 'components/selects/Select'
import { H4, Label, Paragraph } from 'components/typography'
import Modal from 'components/modals/Modal'
import Radio from 'components/inputs/Radio'
import TextArea from 'components/inputs/TextArea'
import MonthSelector from 'components/selects/MonthSelect'
import styles from './AttendanceEditModal.module.css'

const EMPTY_ARRAY = []

function AttendanceEditModal({ attendance, onClose = noop, onUpdate = noop }) {
  const [state, setState] = useState({
    attendance: attendance || {},
    checkedRadio: false,
    newAttendanceInfo: {
      month: new Date(attendance.classStart).getMonth() + 1,
      year: new Date(attendance.classStart).getFullYear(),
      day: new Date(attendance.classStart).getDate(),
      hours: EMPTY_ARRAY
    },
    availableTeacherHours: EMPTY_ARRAY,
    isSaving: false,
    step: 0
  })
  const { setErrorMessage } = useNotificationActions()
  const { classStart, classEnd, subjectName, studentComments } =
    state.attendance
  const { year, month, day, hours } = state.newAttendanceInfo

  const canBeMoved = checkIfCanBeMoved(classStart)

  const { newClassStart, newClassEnd } = useMemo(() => {
    const range = head(mergeHours(hours.sort()))
    if (!range) return { newClassStart: null, newClassEnd: null }
    const [start, end] = range.split(' - ')
    return {
      newClassStart: makeCustomDate(new Date(year, month - 1, day), start),
      newClassEnd: makeCustomDate(new Date(year, month - 1, day), end)
    }
  }, [day, hours, month, year])

  const handleCheckRadio = checked => setState(assoc('checkedRadio', checked))
  const handleStudentCommentsChange = e =>
    setState(assocPath(['attendance', 'studentComments'], e.target.value))

  const handleSaveCanceledAttendance = useCallback(() => {
    setState(assoc('isSaving', true))
    updateAttendance(attendance.id, {
      ...attendance,
      studentComments,
      status: AttendanceStatus.ABSENCE
    })
      .then(() => {
        setState(state => ({ ...state, step: 2, isSaving: false }))
        onUpdate()
      })
      .catch(e => {
        setErrorMessage({ message: e.message })
        setState(assoc('isSaving', false))
      })
  }, [attendance, setErrorMessage, studentComments, onUpdate])

  const handleUpdateAttendance = useCallback(() => {
    setState(assoc('isSaving', true))
    updateAttendance(attendance.id, {
      ...attendance,
      classStart: newClassStart,
      classEnd: newClassEnd
    })
      .then(attendance => {
        setState(state => ({
          ...state,
          attendance: { ...state.attendance, attendance },
          step: 4,
          isSaving: false
        }))
        onUpdate()
      })
      .catch(e => {
        setErrorMessage({ message: e.message })
        setState(assoc('isSaving', false))
      })
  }, [attendance, newClassStart, newClassEnd, onUpdate, setErrorMessage])

  useEffect(() => {
    const targetDate = new Date(year, month - 1, day).toDateString()
    if (attendance.teacherId && targetDate && !isNaN(Date.parse(targetDate)))
      getAvailableHoursBy(targetDate, attendance.teacherId)
        .then(data => {
          setState(state => ({
            ...state,
            availableTeacherHours: data.availableTeacherHours
          }))
        })
        .catch(e =>
          console.error(
            'Error getting available hours by date and teacher: ',
            e
          )
        )
  }, [attendance.teacherId, year, month, day])

  const attendanceView = (
    <div className={styles.modal}>
      <div className={styles.alignedSection}>
        {classStart && (
          <Label className={styles.label}>
            {formatDate({
              date: new Date(classStart),
              stringFormat: 'EEEE, d - MMMM'
            })}
          </Label>
        )}
        <Cross className={styles.closeIcon} onClick={onClose} />
      </div>
      <div className={styles.alignedSection}>
        <H4>{subjectName}</H4>
        <H4
          className={[
            styles.hourRangeInfo,
            !canBeMoved ? styles.disabled : ''
          ].join(' ')}
        >
          <div onClick={() => canBeMoved && setState(assoc('step', 3))}>
            {toTimeString(classStart)} - {toTimeString(classEnd)}
            <Pencil className={styles.pencilIcon} color='var(--purpletronic)' />
          </div>
        </H4>
      </div>
      <Radio
        label='No asistiré'
        checked={state.checkedRadio}
        onCheck={handleCheckRadio}
        disabled={!canBeMoved}
      />
      {state.checkedRadio && (
        <>
          <TextArea
            name='description'
            placeholder='No asistiré a clase porque...'
            value={studentComments}
            rows={2}
            onChange={handleStudentCommentsChange}
          />
          <div>
            <Button
              label='Cancelar clase'
              size='small'
              type='warning'
              disabled={!studentComments}
              onClick={() => setState(assoc('step', 1))}
            />
          </div>
        </>
      )}
      {!canBeMoved && (
        <Label className={styles.cancellationAlert}>
          Las clases deben modificarse con un mínimo de{' '}
          {config.bookingCancellationTime}h de antelación.
        </Label>
      )}
    </div>
  )

  const attendanceCancel = (
    <div className={styles.modal}>
      <Cross className={styles.closeIcon} onClick={onClose} />
      <H4>¿Estás seguro que deseas cancelar esta clase?</H4>
      <div>
        <Paragraph className={styles.paragraph}>
          Aceptando perderás la oportunidad de cambiar la clase.
        </Paragraph>
        <Paragraph className={styles.paragraph}>
          Te recomendamos que cambies la clase a otro horario que te venga
          mejor.
        </Paragraph>
      </div>
      <div className={styles.alignedSection}>
        <Button
          label='Cambiar clase'
          size='small'
          type='warning'
          disabled={state.isSaving}
          onClick={() =>
            setState(state => ({
              ...state,
              step: 3,
              attendance: { ...state.attendance, studentComments: '' }
            }))
          }
        />
        <div className={styles.cancelActions}>
          <Button
            label='Volver'
            size='small'
            type='secondary'
            disabled={state.isSaving}
            onClick={() => setState(assoc('step', 0))}
          />
          <Button
            label='Estoy seguro'
            size='small'
            loading={state.isSaving}
            disabled={state.isSaving}
            onClick={handleSaveCanceledAttendance}
          />
        </div>
      </div>
    </div>
  )

  const attendanceCanceled = (
    <div className={[styles.modal, styles.step2].join(' ')}>
      <Cross className={styles.closeIcon} onClick={onClose} />

      <Paragraph className={styles.paragraph} type='body1Bold'>
        Tu clase ha sido cancelada con éxito
      </Paragraph>
    </div>
  )
  const targetMoth = month - 1
  const currentDate = new Date()
  const days =
    targetMoth < 0
      ? EMPTY_ARRAY
      : Array(getDaysInMonth(new Date(currentDate.getFullYear(), targetMoth)))
          .fill()
          .map((_, index) => ({
            id: index + 1,
            label: index + 1,
            value: index + 1
          }))
  const attendanceChangeDate = (
    <div className={[styles.modal, styles.step3].join(' ')}>
      <Cross className={styles.closeIcon} onClick={onClose} />
      <H4>Selecciona el día y hora que mejor te venga</H4>
      <div className={styles.dateSection}>
        <MonthSelector
          customStyles={{ container: styles.select }}
          value={month}
          maxMenuHeight={135}
          showLabel={false}
          onChange={e =>
            setState(state => ({
              ...state,
              newAttendanceInfo: {
                ...state.newAttendanceInfo,
                month: Number(e.target.value),
                day: null,
                hours: EMPTY_ARRAY
              }
            }))
          }
        />
        <Select
          placeholder='Día'
          customStyles={{ container: styles.select }}
          maxMenuHeight={135}
          options={days}
          value={day}
          onChange={e =>
            setState(state => ({
              ...state,
              newAttendanceInfo: {
                ...state.newAttendanceInfo,
                day: Number(e.target.value),
                hours: EMPTY_ARRAY
              }
            }))
          }
        />
      </div>
      <Select
        placeholder='Horario'
        customStyles={{ container: styles.select }}
        options={state.availableTeacherHours.sort().map(stringHour => ({
          id: stringHour,
          value: stringHour,
          label: stringHour
        }))}
        maxMenuHeight={120}
        value={hours}
        onChange={e =>
          setState(state => ({
            ...state,
            newAttendanceInfo: {
              ...state.newAttendanceInfo,
              hours: e.target.value
            }
          }))
        }
        multiple
      />
      {!isEmpty(hours) && (
        <Paragraph className={styles.newAttendanceInfo} type='body2'>
          Vamos a intentar cambiar tu horario al siguiente:{' '}
          <span>{head(mergeHours(hours.sort()))}</span>
        </Paragraph>
      )}
      {((newClassStart && newClassStart.getTime() <= Date.now()) ||
        !checkIfCanBeMoved(newClassStart)) && (
        <Label className={styles.newAttendanceError}>
          Escoge una configuración correcta y recuerda que las clases deben
          modificarse con un mínimo de {config.bookingCancellationTime}h de
          antelación.
        </Label>
      )}
      <div className={styles.step3Actions}>
        <div>
          <Button
            label='Volver'
            size='small'
            type='secondary'
            onClick={() => setState(assoc('step', 0))}
          />
        </div>
        <div>
          <Button
            label='Cambiar'
            size='small'
            disabled={
              state.isSaving ||
              !newClassStart ||
              newClassStart.getTime() <= Date.now() ||
              !checkIfCanBeMoved(newClassStart)
            }
            onClick={handleUpdateAttendance}
          />
        </div>
      </div>
    </div>
  )

  const attendanceChangeDateSummary = (
    <div className={[styles.modal, styles.step4].join(' ')}>
      <Cross className={styles.closeIcon} onClick={onClose} />

      <Paragraph className={styles.paragraph}>
        ¡Tu clase de <span className={styles.subjectBold}>{subjectName}</span>{' '}
        ha sido cambiada con éxito!
      </Paragraph>
      <div className={styles.alignedSection}>
        {classStart && (
          <Paragraph className={styles.step4Paragraph} type='body2Bold'>
            {formatDate({
              date: new Date(classStart),
              stringFormat: 'EEEE, d - MMMM'
            })}
          </Paragraph>
        )}
        <Paragraph
          className={[
            styles.hourRangeInfo,
            styles.paragraph,
            !canBeMoved ? styles.disabled : ''
          ].join(' ')}
          type='body2Bold'
        >
          {toTimeString(classStart)} - {toTimeString(classEnd)}{' '}
        </Paragraph>
      </div>
    </div>
  )
  return (
    <Modal showActions={false}>
      {state.step === 0 && attendanceView}
      {state.step === 1 && attendanceCancel}
      {state.step === 2 && attendanceCanceled}
      {state.step === 3 && attendanceChangeDate}
      {state.step === 4 && attendanceChangeDateSummary}
    </Modal>
  )
}

export default AttendanceEditModal

/**
 * Check if attendance can be moved
 * @param {Date} classStart
 * @param {Date} [currentDate]
 */
function checkIfCanBeMoved(classStart, currentDate = new Date()) {
  const diff = new Date(classStart).getTime() - currentDate.getTime()

  return !(
    diff < 0 ||
    diff <= Number(config.bookingCancellationTime) * ONE_HOUR_IN_MILLISECONDS
  )
}
