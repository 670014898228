//@ts-check
import React, { useCallback, useMemo, useState } from 'react'
import { identity, isEmpty } from 'ramda'
import {
  DAY_NAME_BY_NUMBER,
  DEFAULT_UNIT_HOURS,
  extractHourAndMinute,
  extractRangeHoursAndMinutes,
  formatDate,
  getMonthDaysInfo,
  isPast,
  isSameDay
} from 'utils/date'
import ChevronLeft from 'assets/icons/ChevronLeft'
import ChevronRight from 'assets/icons/ChevronRight'
import { Paragraph } from 'components/typography'
import styles from './Calendar.module.css'

/**
 * @typedef CalendarProps
 * @property {string} name
 * @property {Date} selectedDate
 * @property {string} startHour
 * @property {string} endHour
 * @property {{am: string[], pm: string[]} | {start: string[], end: string[]}} hours ['10:00 - 11:00'] vs ['10:00', '11:00', ...], single vs broad range
 * @property {boolean} isRange allows select start and end separately
 * @property {boolean} disabled
 * @property {boolean} showLateralHours
 * @property {boolean} disabledPast
 * @property {Date} minDate
 * @property {(date: Date)=>boolean} customCheckDisabled
 * @property {React.ChangeEventHandler<EventTarget>} onChangeDate
 * @property {React.ChangeEventHandler<EventTarget>} onChangeHour
 * @property {React.ChangeEventHandler<EventTarget>} onChangeVisibleMonth
 */

/** @param {Partial<CalendarProps>} props*/
function Calendar({
  name = 'selectedDate',
  selectedDate = new Date(),
  startHour = '',
  endHour = '',
  hours,
  isRange = false,
  disabled = false,
  showLateralHours = true,
  disabledPast = false,
  minDate = new Date(),
  // @ts-ignore
  customCheckDisabled = () => false,
  onChangeDate = identity,
  onChangeHour = identity,
  onChangeVisibleMonth = identity
}) {
  const [state, setState] = useState({
    selectedYear: selectedDate.getFullYear(),
    selectedMonth: selectedDate.getMonth()
  })

  const { selectedMonth, selectedYear } = state

  const handleChangeMonth = useCallback(
    (amount = 0) => {
      if (disabled) return
      let newYear = selectedYear
      let newMonth = selectedMonth + amount
      if (newMonth === 12) {
        newMonth = 0
        newYear++
      } else if (newMonth === -1) {
        newMonth = 11
        newYear--
      }
      setState(state => ({
        ...state,
        selectedYear: newYear,
        selectedMonth: newMonth
      }))
      onChangeVisibleMonth({
        target: {
          // @ts-ignore
          name: 'visibleMonth',
          value: new Date(newYear, newMonth, 1, 0, 0, 0, 0)
        }
      })
    },
    [disabled, onChangeVisibleMonth, selectedMonth, selectedYear]
  )

  const handleChangeDate = useCallback(
    (date = new Date()) => {
      if (disabled) return
      const month = date.getMonth()
      const year = date.getFullYear()
      if (month !== selectedMonth || year !== selectedYear) {
        setState(state => ({
          ...state,
          selectedMonth: month,
          selectedYear: year
        }))
        onChangeVisibleMonth({
          target: {
            // @ts-ignore
            name: 'visibleMonth',
            value: new Date(year, month, 1, 0, 0, 0, 0)
          }
        })
      }
      // @ts-ignore
      onChangeDate({ target: { name, value: date } })
    },
    [
      disabled,
      name,
      onChangeDate,
      onChangeVisibleMonth,
      selectedMonth,
      selectedYear
    ]
  )
  const handleChangeHour = useCallback(
    (hour = '', key = '') => {
      if (!hour || disabled) return
      if (isRange) {
        onChangeHour({
          // @ts-ignore
          target: { name: key, value: hour }
        })
        return
      }
      onChangeHour({
        // @ts-ignore
        target: { name: 'startHour', value: hour.split(' - ')[0] }
      })
      // @ts-ignore
      onChangeHour({ target: { name: 'endHour', value: hour.split(' - ')[1] } })
    },
    [disabled, isRange, onChangeHour]
  )

  const weeks = useMemo(
    () => getMonthDaysInfo(selectedYear, selectedMonth),
    [selectedYear, selectedMonth]
  )
  return (
    <div
      className={[styles.container, disabled ? styles.disabled : ''].join(' ')}
    >
      <div className={styles.calendarWrapper}>
        <Actions
          label={formatDate({
            date: new Date(selectedYear, selectedMonth),
            stringFormat: 'MMMM, yyyy'
          })}
          disabled={disabled}
          onChange={handleChangeMonth}
        />
        <div className={styles.tableWrapper}>
          <table className={styles.table}>
            <thead className={styles.thead}>
              <tr className={styles.tr}>
                {DAY_NAME_BY_NUMBER.map(({ label, day }) => {
                  return (
                    <th key={day} className={styles.th}>
                      {label.substring(0, 3)}
                    </th>
                  )
                })}
              </tr>
            </thead>
            <tbody>
              {weeks.map((week, i) => {
                return (
                  <tr key={i} className={styles.tr}>
                    {week.map((date, i) => {
                      const isDayPast = disabledPast
                        ? isPast(date, minDate)
                        : false
                      const isDateDisabled = customCheckDisabled(date)
                      return (
                        <DayCell
                          key={i}
                          date={date}
                          selectedDate={selectedDate}
                          selectedMonth={selectedMonth}
                          disabled={disabled || isDayPast || isDateDisabled}
                          onChange={handleChangeDate}
                        />
                      )
                    })}
                  </tr>
                )
              })}
            </tbody>
          </table>
        </div>
      </div>

      {showLateralHours && (
        <div className={styles.lateralHours}>
          <p className={styles.lateralHoursTitle}>Horario</p>
          <div className={styles.lateralHoursContent}>
            <HourSelector
              hours={hours}
              isRange={isRange}
              startHour={startHour}
              endHour={endHour}
              selectedDate={selectedDate}
              disabledPast={disabledPast}
              onChange={handleChangeHour}
            />
          </div>
        </div>
      )}
    </div>
  )
}

function HourSelector(props) {
  const {
    hours = DEFAULT_UNIT_HOURS,
    isRange,
    startHour,
    endHour,
    selectedDate,
    disabledPast,
    onChange
  } = props
  if (!hours) return <Paragraph>Sin horas disponibles</Paragraph>
  if (isRange) {
    if (isEmpty(hours.start) && isEmpty(hours.end))
      return (
        <Paragraph className={styles.emptyHours}>
          Sin horas disponibles
        </Paragraph>
      )
    return (
      <>
        <HourSkeleton
          title='Inicio'
          hours={hours.start}
          selectedHour={startHour}
          selectedDate={selectedDate}
          disabledPast={disabledPast}
          onChange={hour => onChange(hour, 'startHour')}
          isRange
        />
        <HourSkeleton
          title='Fin'
          hours={hours.end}
          selectedHour={endHour}
          selectedDate={selectedDate}
          disabledPast={disabledPast}
          onChange={hour => onChange(hour, 'endHour')}
          isRange
        />
      </>
    )
  }
  const selectedHour = startHour && endHour ? `${startHour} - ${endHour}` : null

  if (isEmpty(hours.am) && isEmpty(hours.pm))
    return (
      <Paragraph className={styles.emptyHours}>Sin horas disponibles</Paragraph>
    )

  return (
    <>
      <HourSkeleton
        title='Mañana'
        hours={hours.am || DEFAULT_UNIT_HOURS.am}
        selectedHour={selectedHour}
        selectedDate={selectedDate}
        disabledPast={disabledPast}
        onChange={onChange}
      />
      <HourSkeleton
        title='Tarde'
        hours={hours.pm || DEFAULT_UNIT_HOURS.pm}
        selectedHour={selectedHour}
        selectedDate={selectedDate}
        disabledPast={disabledPast}
        onChange={onChange}
      />
    </>
  )
}

function HourSkeleton({
  title = '',
  hours = [],
  selectedHour,
  selectedDate,
  disabledPast = false,
  isRange = false,
  onChange
}) {
  if (!hours || !hours.length) return null
  return (
    hours.length && (
      <div className={styles.hourSection}>
        <p className={styles.hourSectiontitle}>{title}</p>
        <div className={styles.hourContainer}>
          {hours.map((hour, index) => {
            const disabled = isDisabledHour(
              isRange,
              hour,
              disabledPast,
              selectedDate
            )

            return (
              <p
                key={index}
                className={[
                  styles.hour,
                  selectedHour === hour ? styles.selectedHour : '',
                  disabled ? styles.disabledHour : ''
                ].join(' ')}
                onClick={() => !disabled && onChange(hour)}
              >
                {hour}
              </p>
            )
          })}
        </div>
      </div>
    )
  )
}

function Actions({ label, disabled, onChange }) {
  return (
    <div className={styles.actions}>
      <ChevronLeft
        className={[
          styles.chevron,
          disabled ? styles.chevronDisabled : ''
        ].join(' ')}
        onClick={() => onChange(-1)}
      />
      <p className={styles.title}>{label}</p>
      <ChevronRight
        className={[
          styles.chevron,
          disabled ? styles.chevronDisabled : ''
        ].join(' ')}
        onClick={() => onChange(1)}
      />
    </div>
  )
}

function DayCell({ date, selectedDate, selectedMonth, disabled, onChange }) {
  const day = date.getDate()
  const month = date.getMonth()
  const isToday = isSameDay(new Date(), date)
  const isSelectedDate = isSameDay(selectedDate, date)

  return (
    <td
      className={[
        styles.dayCell,
        isToday ? styles.today : '',
        selectedMonth !== month ? styles.dayCellOutOfRange : '',
        disabled ? styles.dayCellDisabled : ''
      ].join(' ')}
      onClick={() => !disabled && onChange(date)}
    >
      <p className={isSelectedDate ? styles.selectedDate : ''}>{day}</p>
    </td>
  )
}
export default Calendar

function isDisabledHour(isRange, hour, disabledPast, selectedDate) {
  if (!disabledPast) return false
  let endHour = isRange
    ? extractHourAndMinute(hour)
    : {
        hour: extractRangeHoursAndMinutes(hour).maxHour,
        minute: extractRangeHoursAndMinutes(hour).maxMinutes
      }
  return (
    new Date(selectedDate).setHours(endHour.hour, endHour.minute, 0, 0) <
    Date.now()
  )
}
