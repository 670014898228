//@ts-check
import React, { useCallback, useEffect, useRef, useState } from 'react'
import { useReactToPrint } from 'react-to-print'
import { Link } from 'react-router-dom'
import { isEmpty } from 'ramda'
import html3pdf from 'html3pdf'
import { SignedByTypes } from 'utils/constants'
import { createServiceTerms } from 'api/serviceTerms'
import { useAuth } from 'context/AuthProvider'
import { useNotificationActions } from 'context/NotificationProvider'
import useFormManager from 'hooks/useFormManager'
import Button from 'components/buttons/Button'
import { H3, Paragraph } from 'components/typography'
import Modal from 'components/modals/Modal'
import Stepper from 'components/stepper'
import Form from './Form'
import Conditions from './Conditions'
import styles from './ServiceTerms.module.css'

const REQUIRED_FIELDS = ['fullname', 'dni', 'signature']
function ServiceTerms() {
  const { student, setStudent } = useAuth()
  const [currentStep, setCurrentStep] = useState(
    student.serviceTerms?.url ? 2 : 0
  )
  const [showTutorAlert, setShowTutorAlert] = useState(false)
  const [isSaving, setIsSaving] = useState(false)
  const refPrint = useRef(null)
  const { setSuccessMessage, setErrorMessage } = useNotificationActions()

  const {
    form,
    handleFormChange,
    getErrorPropsByRequiredField,
    hasPendingRequiredFields
  } = useFormManager(REQUIRED_FIELDS, {
    fullname: '',
    dni: '',
    signature: ''
  })

  const handlePrint = useReactToPrint({
    content: () => refPrint.current,
    copyStyles: true,
    removeAfterPrint: true,
    print: async printIframe => {
      const document = printIframe.contentDocument
      if (document) {
        try {
          const html = document.getElementsByTagName('html')[0]
          const output = await html3pdf()
            .from(html)
            .set({
              html2canvas: { scale: 4 },
              jsPDF: {
                orientation: 'p',
                unit: 'mm',
                format: 'a4',
                floatPrecision: 16 // or "smart", default is 16
              }
            })
            .outputPdf('blob')
          const serviceTerms = await createServiceTerms({
            fullname: form.fullname,
            dni: form.dni,
            file: output,
            signedBy: !student.isAnAdult
              ? SignedByTypes.tutor
              : SignedByTypes.student
          })
          setSuccessMessage({
            title: 'Firmado correctamente'
          })
          setStudent({ serviceTerms })
        } catch (error) {
          console.error('Error getting pdf:', error)
          setErrorMessage({ message: error.message })
          setCurrentStep(1)
        }
      }
      setIsSaving(false)
    },
    onPrintError: (location, e) => {
      console.error('Error in react-to-print: ', e)
      setIsSaving(false)
    }
  })

  const handleSubmit = useCallback(() => {
    setIsSaving(true)
    setCurrentStep(2)
    setTimeout(async () => {
      refPrint.current && handlePrint()
    }, 600)
  }, [handlePrint])

  useEffect(() => {
    if (!isEmpty(student) && currentStep === 0)
      if (!student.isAnAdult) setShowTutorAlert(true)
  }, [currentStep, student])

  const closeModal = () => setShowTutorAlert(false)

  const steps = [
    {
      title: 'Condiciones generales',
      component: (
        <>
          <div className={styles.card}>
            <div className={styles.firstStepContent}>
              <Conditions showDecorations={false} />
            </div>
          </div>

          {showTutorAlert && (
            <Modal onOk={closeModal} hideCancelButton>
              <div className={styles.modalContent}>
                <H3>Acción necesaria</H3>
                <Paragraph className={styles.modalParagraph} type='body1Bold'>
                  El documento debe firmarlo tu tutor legal
                </Paragraph>
              </div>
            </Modal>
          )}
        </>
      )
    },
    {
      title: 'Firmar',
      component: (
        <Form
          isAnAdult={student.isAnAdult}
          form={form}
          onChange={handleFormChange}
          getErrorPropsByRequiredField={getErrorPropsByRequiredField}
        />
      )
    },
    {
      title: 'Descargar archivo',
      component: (
        <>
          <div style={{ display: 'none' }}>
            <Conditions
              // @ts-ignore
              ref={refPrint}
              studentSignature={student.isAnAdult && form.signature}
              tutorSignature={!student.isAnAdult && form.signature}
              studentName={student.isAnAdult ? form.fullname : student.name}
              signedAt={new Date()}
              showDecorations={true}
            />
          </div>
          <div className={styles.thirdStep}>
            <Link to='/account'>
              <Button size='small' type='secondary' label='Volver' />
            </Link>

            <a href={student.serviceTerms?.url} target='_blank'>
              <Button
                size='small'
                label={isSaving ? 'Generando...' : 'Descargar'}
                disabled={isSaving}
              />
            </a>
          </div>
        </>
      )
    }
  ]

  return (
    <section className={styles.section}>
      <Stepper
        activeStep={currentStep}
        steps={steps}
        bottomToolbar={
          <div className={styles.buttonContainer}>
            {currentStep > 0 && currentStep < 2 && (
              <Button
                size='small'
                type='secondary'
                label='Atrás'
                loading={isSaving}
                disabled={isSaving}
                onClick={() => setCurrentStep(currentStep - 1)}
              />
            )}

            {currentStep === 0 && (
              <Button
                size='small'
                label='Aceptar y firmar'
                onClick={() => setCurrentStep(currentStep + 1)}
              />
            )}
            {currentStep === 1 && (
              <Button
                size='small'
                label='Firmar'
                onClick={handleSubmit}
                loading={isSaving}
                disabled={hasPendingRequiredFields || isSaving}
              />
            )}
          </div>
        }
      />
    </section>
  )
}

export default ServiceTerms
