//@ts-check
import React, { useCallback, useState } from 'react'
import { assocPath, dissocPath } from 'ramda'
import { H2, Paragraph } from 'components/typography'
import SubjectSelect from 'components/selects/SubjectSelect'
import Button from 'components/buttons/Button'
import Slider from 'components/inputs/Slider'
import Cross from 'assets/icons/Cross'
import styles from './RateEditor.module.css'

const DEFAULT_HOURS = 2

function RateEditor({ rate, isSaving = false, onSave = rate => {} }) {
  const [updatedRate, setUpdatedRate] = useState(rate)
  const [subjectsById, setSubjectsById] = useState({})

  const extractSubjectsById = useCallback(subjectsById => {
    setSubjectsById(subjectsById)
  }, [])

  const handleHoursBySubject = (subjectId, hours) => {
    setUpdatedRate(assocPath(['subjects', subjectId], hours))
  }

  const handleAddSubject = e => {
    const { value: subjectId } = e.target
    setUpdatedRate(assocPath(['subjects', subjectId], DEFAULT_HOURS))
  }

  const handleRemoveSubject = subjectId => {
    setUpdatedRate(dissocPath(['subjects', subjectId]))
  }
  const selectedSubjects = Object.keys(updatedRate.subjects)
  return (
    <div className={styles.recommendation}>
      <H2>Crea tu plan personalizado</H2>
      <Paragraph>
        Te hemos preparado tarifa recomendada que consideramos que te ayudará a
        conseguir tus objetivos, pero siéntete libre, personaliza y configúrala
        a tu manera. Siempre podrás contactar con un experto para que te ayude a
        elegir la mejor opción
      </Paragraph>
      <div className={styles.recommendationActions}>
        <div className={styles.subjectSelect}>
          <SubjectSelect
            placeholder='Elige una asignatura'
            showLabel={false}
            onChange={handleAddSubject}
            extractSubjectsById={extractSubjectsById}
          />
        </div>
        <Button
          label='Contratar'
          loading={isSaving}
          disabled={!selectedSubjects.length}
          onClick={() => onSave(updatedRate)}
        />
      </div>
      {selectedSubjects.map(id => {
        const { name, level } = subjectsById[id] || {}
        const hours = updatedRate.subjects[id]
        return (
          <div key={id} className={styles.subjectConfigRow}>
            <div className={styles.subjectNameLabel}>
              {name || '---'}
              {level && <div className={styles.subjectTooltip}>{level}</div>}
            </div>
            <div className={styles.subjectOptions}>
              <Slider
                value={hours}
                onChange={hours => handleHoursBySubject(id, hours)}
              />
              <Cross
                className={styles.removeIcon}
                onClick={() => handleRemoveSubject(id)}
                color='var(--sandground)'
              />
            </div>
          </div>
        )
      })}
    </div>
  )
}

export default RateEditor
