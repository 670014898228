//@ts-check
import React, { useMemo, useState } from 'react'
import { isEmpty } from 'ramda'
import { noop } from 'utils'
import { H4, Paragraph } from 'components/typography'
import styles from './RecommendedSubscriptionCard.module.css'
import { SubscriptionConfigTypes } from 'utils/constants'
import Slider from 'components/inputs/Slider'
import Button from 'components/buttons/Button'

const WEEKS = 4
const OPEN_DAYS = 6
//TODO: va a cambiar esto
function RecommendedSubscriptionCard({ budget = {}, onBuy = noop }) {
  const [hours, setHours] = useState(50)
  const formattedData = useMemo(() => {
    if (!budget || isEmpty(budget)) return
    const { subscriptionConfig } = budget
    return {
      name: subscriptionConfig.name,
      subjectsLimit: makeSubjectLimit(subscriptionConfig),
      limit: makeLimit(subscriptionConfig),
      duration: makeDuration(subscriptionConfig)
    }
  }, [budget])
  if (!formattedData) return null
  return (
    <div className={styles.card}>
      <H4 className={styles.title}>{formattedData.name}</H4>
      <div className={styles.textInfo}>
        <Paragraph type='body2'>{formattedData.subjectsLimit}</Paragraph>
        <Paragraph className={styles.limitInfo} type='body2'>
          {formattedData.limit}
        </Paragraph>
        <Paragraph type='body2'>{formattedData.duration}</Paragraph>
      </div>
      {/* TODO: ES NECESARIO, SOLO PODRÍAMOS SACAR ALGO APROXIMADO Y CHOCARÍA CON LOS VALORES LÍMITES QUE CUALES SERÍAN ??? */}
      <Slider value={hours} onChange={setHours} />
      <Paragraph className={styles.total}>{400}€/h</Paragraph>

      <Button label='Comprar' size='small' />
    </div>
  )
}

function makeSubjectLimit(s) {
  switch (s.type) {
    case SubscriptionConfigTypes.extraHours:
    case SubscriptionConfigTypes.pack:
    case SubscriptionConfigTypes.prepareMornings:
      return s.config.subjectsLimit > 1
        ? s.config.subjectsLimit + ' Asignaturas'
        : '1 Asignatura'
    case SubscriptionConfigTypes.unlimited:
      return 'Sin límite de asignaturas'
    case SubscriptionConfigTypes.reinforces: {
      const count = s.config.subjectRestrictions.reduce((acc = 0, r) => {
        return (acc += r.subjectsLimit)
      }, 0)
      return count > 1 ? count + ' Asignaturas' : '1 Asignatura'
    }
    default: {
      console.warn('Unknown type:', s.type)
      return '??'
    }
  }
}

function makeLimit(s) {
  switch (s.type) {
    case SubscriptionConfigTypes.extraHours:
    case SubscriptionConfigTypes.pack:
      return s.config.hours + 'h'
    case SubscriptionConfigTypes.prepareMornings:
      return s.config.hoursLimitByWeek * WEEKS + 'h'
    case SubscriptionConfigTypes.unlimited:
      return `${s.config.hoursLimitByDay}h/día`
    case SubscriptionConfigTypes.reinforces:
      return [
        ...s.config.subjectRestrictions,
        ...s.config.subjectRestrictions
      ].map((r, i) => (
        <span key={i}>
          <span>
            {r.subjectsLimit}{' '}
            {r.subjectsLimit === 1 ? 'asignatura ' : 'asignaturas '}
            de {r.type}
          </span>
          <br />
          <span>{r.hoursLimitByWeek}h/semana</span>
          <br />
        </span>
      ))
    default: {
      console.warn('Unknown type:', s.type)
      return '??'
    }
  }
}
function makeDuration(s) {
  switch (s.type) {
    case SubscriptionConfigTypes.extraHours:
    case SubscriptionConfigTypes.pack:
      return `${s.config.daysLimit} días de duración`
    case SubscriptionConfigTypes.prepareMornings:
    case SubscriptionConfigTypes.unlimited:
    case SubscriptionConfigTypes.reinforces:
    case SubscriptionConfigTypes.reinforces:
      return 'Duración mensual'
    default: {
      console.warn('Unknown type:', s.type)
      return '???'
    }
  }
}
export default RecommendedSubscriptionCard
