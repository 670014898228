//@ts-check
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { Link } from 'react-router-dom'
import { assoc, isEmpty, propEq, without } from 'ramda'
import Back from 'assets/icons/Back'
import Info from 'assets/icons/Info'
import Cross from 'assets/icons/Cross'
import { findSubscriptions } from 'api/subscriptions'
import { findAttendanceDTOs } from 'api/attendances'
import {
  makeMothlyHoursByTeacher,
  getMothlyOptionsBySubject,
  saveMonthlyOptions
} from 'api/calendar'
import { DAY_NAME_BY_NUMBER, dateTimeToString, dateToString } from 'utils/date'
import { SubscriptionConfigTypes } from 'utils/constants'
import { useNotificationActions } from 'context/NotificationProvider'
import useSubjects from 'hooks/useSubjects'
import Button from 'components/buttons/Button'
import Select from 'components/selects/Select'
import { H2, H4, Label, Paragraph } from 'components/typography'
import WeeklyCalendar from 'components/calendars/WeeklyCalendar'
import MonthlyCalendar from 'components/calendars/MonthlyCalendar'
import AttendanceCalendar from 'components/calendars/AttendanceCalendar'
import VerticalTecherCard from 'components/cards/teacher/VerticalTecherCard'
import Modal from 'components/modals/Modal'
import AttendanceEditModal from './AttendanceEditModal'
import styles from './Attendances.module.css'

const weekOptions = [
  {
    date: new Date(2023, 7, 21),
    hours: [
      '10:00 - 11:00',
      '11:00 - 12:00',
      '12:00 - 13:00',
      '13:00 - 14:00',
      '16:30 - 17:30',
      '17:30 - 18:30',
      '18:30 - 19:30',
      '19:30 - 20:30'
    ]
  },
  {
    date: new Date(2023, 7, 22),
    hours: []
  }
]

function Attendances() {
  //Nos traemos todas las asignaturas de la tarifa o packs que están "activas"
  const [state, setState] = useState({
    step: 0,
    subjectsForSelect: [],
    teachers: [],
    selectedSubjectId: null,
    selectedSubscriptionId: null,
    subscriptions: [],
    monthlyOptions: {},
    weeklyOptions: [], //TODO: object with date by id?
    attendances: [],
    selectedAttendanceId: null,
    hoursByTeacherId: {},
    sortedTeacherIds: [],
    selectedTeacherToShowInfo: null,
    isSearchingOptions: false,
    isSearchingTeachers: false,
    isSavingCalendarOptions: false,
    showModal: false
  })
  const [monthlyHoursByDay, setMonthlyHoursByDay] = useState({})
  const [weeklyHoursByDate, setweeklyHoursByDate] = useState({})
  const { setSuccessMessage, setErrorMessage } = useNotificationActions()
  const {
    subjects,
    subjectsById,
    isFetching: isFetchingSubjects
  } = useSubjects()

  const {
    step,
    subjectsForSelect,
    teachers,
    selectedSubjectId,
    selectedSubscriptionId,
    subscriptions,
    monthlyOptions,
    weeklyOptions,
    attendances,
    selectedAttendanceId,
    hoursByTeacherId,
    sortedTeacherIds,
    selectedTeacherToShowInfo,
    isSearchingOptions,
    isSearchingTeachers,
    isSavingCalendarOptions,
    showModal
  } = state

  const selectedAttendance = useMemo(() => {
    if (!selectedAttendanceId) return {}
    return attendances.find(propEq('id', selectedAttendanceId))
  }, [attendances, selectedAttendanceId])

  const selectedSubscription = useMemo(() => {
    return subscriptions.find(({ id }) => id === selectedSubscriptionId) || {}
  }, [selectedSubscriptionId, subscriptions])

  const isPack = [
    SubscriptionConfigTypes.pack,
    SubscriptionConfigTypes.extraHours
  ].includes(selectedSubscription?.subscriptionType)

  const handleSubjectChange = e => {
    const selectedSubjectId = e.target.value
    setState(state => ({
      ...state,
      selectedSubjectId,
      monthlyOptions: {},
      weeklyOptions: [],
      isSearchingOptions: true
    }))
    setMonthlyHoursByDay({})
    setweeklyHoursByDate({})
  }

  const handleMonthlyHoursByDayChange = (hour, dayNumber) => {
    setMonthlyHoursByDay(state => {
      const currentHours = state[dayNumber] || []
      let newHours = []
      if (currentHours.includes(hour)) newHours = without([hour], currentHours)
      else newHours = currentHours.concat(hour)

      let newState = { ...state, [dayNumber]: newHours }
      if (isEmpty(newHours)) delete newState[dayNumber]
      return newState
    })
  }
  const handleWeeklyHoursByDateChange = (hour, targetStringDate) => {
    setweeklyHoursByDate(state => {
      const currentHours = state[targetStringDate] || []
      let newHours = []
      if (currentHours.includes(hour)) newHours = without([hour], currentHours)
      else newHours = currentHours.concat(hour)
      return { ...state, [targetStringDate]: newHours }
    })
  }

  const handleSearchTeacherInfo = useCallback(() => {
    setState(assoc('isSearchingTeachers', true))

    return makeMothlyHoursByTeacher({
      subscriptionId: selectedSubscriptionId,
      subjectId: selectedSubjectId,
      monthlyHoursByDay
    })
      .then(({ hoursByTeacherId, sortedTeacherIds, teachers }) => {
        setState(state => ({
          ...state,
          hoursByTeacherId,
          sortedTeacherIds,
          teachers,
          isSearchingTeachers: false,
          step: 2
        }))
      })
      .catch(e => {
        console.error('Error searching target teachers: ', e)
        setErrorMessage({
          message: 'No se han podido encontrar ningún profesor'
        })
        setState(assoc('isSearchingTeachers', false))
      })
  }, [
    monthlyHoursByDay,
    selectedSubjectId,
    selectedSubscriptionId,
    setErrorMessage
  ])

  const toggleShowAttendanceCalendar = () =>
    setState(state => ({
      ...state,
      selectedSubjectId: null
    }))

  const toggleShowModal = useCallback(() => {
    setState(s => ({
      ...s,
      showModal: !s.showModal,
      selectedAttendanceId: !isEmpty(selectedAttendance)
        ? null
        : s.selectedAttendanceId
    }))
  }, [selectedAttendance])

  const handleEdit = useCallback(
    attendanceId => {
      setState(assoc('selectedAttendanceId', attendanceId))
      toggleShowModal()
    },
    [toggleShowModal]
  )

  const fetchAttendances = useCallback(() => {
    findAttendanceDTOs({ subscriptionId: selectedSubscriptionId })
      .then(attendances => setState(assoc('attendances', attendances)))
      .catch(e => {
        console.error('Error fetching attendances: ' + e)
        //TODO: block editor?
      })
  }, [selectedSubscriptionId])

  const handleSaveCalendarOptions = useCallback(() => {
    setState(assoc('isSavingCalendarOptions', true))
    if (isPack) return
    saveMonthlyOptions({
      subscriptionId: selectedSubscriptionId,
      budgetId: selectedSubscription.budgetId,
      subjectId: selectedSubjectId,
      teacherId: selectedTeacherToShowInfo,
      monthlyHoursByDay
    })
      .then(() => {
        setSuccessMessage({})
        fetchAttendances()
        //TODO: show results failed, success
        setState(state => ({
          ...state,
          isSavingCalendarOptions: false,
          selectedSubjectId: null,
          monthlyOptions: {},
          selectedTeacherToShowInfo: null,
          step: 1
        }))
        setMonthlyHoursByDay({})
      })
      .catch(e => {
        console.error('Error saving monthly options: ', e)
        setErrorMessage({
          message: 'No se han podido guardar tu configuración'
        })
        setState(assoc('isSavingCalendarOptions', false))
      })
  }, [
    isPack,
    monthlyHoursByDay,
    selectedSubjectId,
    selectedSubscription.budgetId,
    selectedSubscriptionId,
    selectedTeacherToShowInfo,
    setErrorMessage,
    setSuccessMessage,
    fetchAttendances
  ])

  useEffect(() => {
    if (selectedSubscriptionId) fetchAttendances()
  }, [fetchAttendances, selectedSubscriptionId])

  useEffect(() => {
    if (selectedSubscriptionId) {
      const { selectedSubjectIds = [], selectedSupplementIds = [] } =
        subscriptions.find(({ id }) => id === selectedSubscriptionId) || {}

      setState(state => ({
        ...state,
        selectedSubjectId: null,
        subjectsForSelect: subjects.filter(
          ({ id }) =>
            [...selectedSubjectIds, ...selectedSupplementIds].includes(id) &&
            !attendances.map(({ subjectId }) => subjectId).includes(id)
        )
      }))
      setMonthlyHoursByDay({})
      setweeklyHoursByDate({})
    } else {
      setState(state => ({
        ...state,
        subjectsForSelect: [],
        selectedSubscriptionId: null,
        selectedSubjectId: null,
        monthlyOptions: {},
        weeklyOptions: []
      }))
    }
  }, [attendances, selectedSubscriptionId, subjects, subscriptions])

  useEffect(() => {
    if (selectedSubjectId) {
      //TODO: fetch hours
      if (isPack) return
      else
        getMothlyOptionsBySubject(selectedSubjectId)
          .then(monthlyOptions => {
            setState(state => ({
              ...state,
              monthlyOptions,
              isSearchingOptions: false
            }))
          })
          .catch(e => {
            console.error('Error fetching monthlyHours:', e)
            setErrorMessage({
              message: 'Ha ocurrido un error buscando opciones para tu clase'
            })
            setState(state => ({ ...state, isSearchingOptions: false }))
          })
    }
  }, [isPack, selectedSubjectId, setErrorMessage])

  useEffect(() => {
    findSubscriptions({ projectExtraData: true })
      .then(subscriptions =>
        setState(state => ({
          ...state,
          subscriptions: subscriptions.map(s => {
            s.label = `${s.subscriptionName} (${dateTimeToString(s.startDate)})`
            s.value = s.id
            return s
          })
        }))
      )
      .catch(e => {
        console.error('Error getting subscriptions by student: ', e)
      })
  }, [])

  if (!subscriptions.length)
    return (
      <section className={[styles.section, styles.topSection].join(' ')}>
        <H2>Configura tu horario por asignatura</H2>
        <Paragraph>
          Para poder configurar tu horario primero necesitas elegir tus
          asignaturas.
        </Paragraph>
        <Link to='/subscriptions'>
          <Button label='Contratar asignaturas' />
        </Link>
      </section>
    )

  const disabledChooseTeacher =
    isSearchingOptions ||
    isSearchingTeachers ||
    (isPack ? isEmpty(weeklyHoursByDate) : isEmpty(monthlyHoursByDay))

  const step0 = (
    <>
      <div className={styles.topSection}>
        <H2>Configura tu horario por asignatura</H2>
        <Paragraph>Selecciona la subscripción que quieres modificar.</Paragraph>
        <Select
          customStyles={{ container: styles.subjectSelect }}
          value={selectedSubscriptionId}
          options={subscriptions}
          placeholder='Suscripción'
          onChange={e =>
            setState(state => ({
              ...state,
              selectedSubscriptionId: e.target.value,
              step: 1
            }))
          }
          autofocus
        />
      </div>
    </>
  )

  const step1 = (
    <>
      <div className={styles.topSection}>
        <H2>
          <Back
            color='var(--sandground)'
            className={styles.backIcon}
            onClick={() =>
              setState(state => ({
                ...state,
                step: 0,
                selectedSubscriptionId: null
              }))
            }
          />
          Configura tu horario por asignatura para tu{' '}
          {isPack ? 'pack' : 'tarifa'}
          <br />{' '}
          {`${selectedSubscription.subscriptionName} (${dateToString(
            selectedSubscription.startDate
          )})`}
        </H2>
        <Paragraph>
          Selecciona la asignatura y el horario que mejor te convenga.
          <br />
          Podrás modificarla en cualquier momento.
        </Paragraph>

        <div className={styles.toolbar}>
          {!isEmpty(subjectsForSelect) && (
            <Select
              customStyles={{ container: styles.subjectSelect }}
              value={selectedSubjectId}
              options={subjectsForSelect}
              placeholder='Asignatura'
              isLoading={isFetchingSubjects}
              onChange={handleSubjectChange}
            />
          )}
          {!isEmpty(attendances) && selectedSubjectId && (
            <Button
              label='Ver asistencias'
              size='small'
              onClick={toggleShowAttendanceCalendar}
            />
          )}
        </div>
      </div>
      {selectedSubjectId ? (
        <div className={styles.calendarSection}>
          {isSearchingOptions && (
            <Paragraph>Buscando horas disponibles...</Paragraph>
          )}
          {!isPack && (
            <MonthlyCalendar
              values={monthlyHoursByDay}
              options={monthlyOptions}
              onChange={handleMonthlyHoursByDayChange}
              disabled={
                isSearchingOptions ||
                isSavingCalendarOptions ||
                isSearchingTeachers
              }
            />
          )}
          {isPack && (
            <WeeklyCalendar
              startDate={new Date(selectedSubscription.startDate)}
              values={weeklyHoursByDate}
              options={weekOptions}
              onChange={handleWeeklyHoursByDateChange}
              disabledPast
            />
          )}
          <div className={styles.saveButton}>
            <Button
              loading={isSearchingTeachers}
              disabled={disabledChooseTeacher}
              onClick={handleSearchTeacherInfo}
            />
          </div>
        </div>
      ) : (
        <div className={styles.attendanceCalendar}>
          <AttendanceCalendar attendances={attendances} onEdit={handleEdit} />
        </div>
      )}
      {showModal && (
        <AttendanceEditModal
          attendance={selectedAttendance}
          onClose={toggleShowModal}
          onUpdate={fetchAttendances}
        />
      )}
    </>
  )

  const getTargetTeacher = teacherId => teachers.find(propEq('_id', teacherId))
  const step2 = (
    <>
      <div className={styles.topSection}>
        <H2>
          <Back
            color='var(--sandground)'
            className={styles.backIcon}
            onClick={() =>
              setState(state => ({
                ...state,
                step: 1
              }))
            }
          />
          Configura tu horario por asignatura
        </H2>
        <Paragraph>
          Selecciona el profesor que prefieras y más se ajuste a tu horario.
        </Paragraph>
      </div>
      <div className={styles.teacherList}>
        {sortedTeacherIds?.map((teacherId, index) => {
          const teacher = getTargetTeacher(teacherId)
          if (!teacher) return null
          const commonClick = () =>
            setState(state => ({
              ...state,
              selectedTeacherToShowInfo: teacherId
            }))
          return (
            <div key={teacherId} className={styles.teacherInfo}>
              <VerticalTecherCard
                teacher={teacher}
                subjectsById={subjectsById}
                onClick={commonClick}
                buttonLabel='Agendar clases'
              />
              <Info className={styles.icon} onClick={commonClick} />
            </div>
          )
        })}
        {selectedTeacherToShowInfo && (
          <Modal
            okText='Agendar clases'
            onOk={handleSaveCalendarOptions}
            isLoading={isSavingCalendarOptions}
            hideCancelButton
          >
            <div className={styles.teacherModalInfo}>
              <Cross
                className={styles.closeIcon}
                onClick={() =>
                  !isSavingCalendarOptions &&
                  setState(state => ({
                    ...state,
                    selectedTeacherToShowInfo: null
                  }))
                }
              />
              <H4>{getTargetTeacher(selectedTeacherToShowInfo)?.name}</H4>
              <Paragraph className={styles.paragraph}>
                Tu porfe tiene las siguientes horas disponibles:
              </Paragraph>
              <MonthlyInfo data={hoursByTeacherId[selectedTeacherToShowInfo]} />
            </div>
          </Modal>
        )}
      </div>
    </>
  )

  return (
    <section className={styles.section}>
      {step === 0 && step0}
      {step === 1 && step1}
      {step === 2 && step2}
    </section>
  )
}

export default Attendances

function MonthlyInfo({ data }) {
  const dayNumbers = Object.keys(data)
  return (
    <>
      <div>
        {dayNumbers.map(dayNumber => {
          const { label } = DAY_NAME_BY_NUMBER.find(
            propEq('day', Number(dayNumber))
          )
          const hours = data[dayNumber]
          return (
            <Paragraph key={dayNumber} className={styles.paragraph}>
              <span className={styles.dayLabel}>{label}</span>:{' '}
              {hours.join(', ')}
            </Paragraph>
          )
        })}
      </div>

      <Label className={styles.advice}>
        Puede darse el caso de que alguna hora no pueda añadirse debido al nivel
        de ocupación
      </Label>
    </>
  )
}
