//@ts-check
import React, { useEffect, useState } from 'react'
import QRCode from 'qrcode.react'
import { H2, H3, Paragraph } from 'components/typography'
import MonthSelect from 'components/selects/MonthSelect'
import YearSelect from 'components/selects/YearSelect'
import Modal from 'components/modals/Modal'
import Button from 'components/buttons/Button'
import { dateToString, getCurrentMonth } from 'utils/date'
import { fetchPhotos } from 'api/photos'
import ChevronRight from 'assets/icons/ChevronRight'
import ChevronLeft from 'assets/icons/ChevronLeft'
import { useAuth } from 'context/AuthProvider'
import config from 'config'
import styles from './Gallery.module.css'

function Gallery() {
  const [query, setQuery] = useState(getCurrentMonth())
  const [photos, setPhotos] = useState([])
  const [selectedPhotoIndex, setSelectedPhotoIndex] = useState(null)
  const [showUploadModal, setShowUploadModal] = useState(false)
  const { student } = useAuth()

  useEffect(() => {
    if (query.month && query.year) {
      fetchPhotos({ month: query.month, year: query.year })
        .then(data => {
          const sortedPhotos = data.sort(
            (a, b) => new Date(b.date) - new Date(a.date)
          )
          setPhotos(sortedPhotos)
        })
        .catch(error => {
          console.error('Error al obtener las fotos:', error)
        })
    }
  }, [query])

  const handleDateChange = key => e => {
    const value = e.target.value
    setQuery(currentDate => ({
      ...currentDate,
      [key]: value
    }))
  }

  const handlePrevPhoto = () => {
    setSelectedPhotoIndex(prevIndex =>
      prevIndex === 0 ? photos.length - 1 : prevIndex - 1
    )
  }

  const handleNextPhoto = () => {
    setSelectedPhotoIndex(prevIndex =>
      prevIndex === photos.length - 1 ? 0 : prevIndex + 1
    )
  }
  const openUploadModal = () => {
    setShowUploadModal(true)
  }
  const closeUploadModal = () => {
    setShowUploadModal(false)
  }

  return (
    <section className={styles.section}>
      <H2>Comparte tus pensamientos 🧠</H2>
      <div className={styles.actionContainer}>
        <div className={styles.dateContainer}>
          <div className={styles.monthSelector}>
            <MonthSelect
              value={query.month}
              showLabel={false}
              onChange={handleDateChange('month')}
            />
          </div>
          <div className={styles.yearSelector}>
            <YearSelect
              value={query.year}
              showLabel={false}
              onChange={handleDateChange('year')}
            />
          </div>
        </div>
        <Button size='small' onClick={openUploadModal}>
          Subir foto
        </Button>
      </div>

      <div className={styles.photoContainer}>
        {photos.length > 0 ? (
          photos.map((photo, index) => (
            <div
              key={photo.date}
              className={styles.photoItem}
              onClick={() => setSelectedPhotoIndex(index)}
            >
              <div>
                <img
                  key={photo.thumbnail}
                  className={styles.photo}
                  src={photo.thumbnail}
                  alt='Foto alumno'
                />
              </div>
              <p className={styles.photoDate}> {dateToString(photo.date)}</p>
            </div>
          ))
        ) : (
          <Paragraph>No has subido ninguna foto este mes</Paragraph>
        )}
      </div>

      {selectedPhotoIndex !== null && (
        <Modal
          cancelText='Cerrar'
          hideOkButton={true}
          onCancel={() => setSelectedPhotoIndex(null)}
        >
          <div className={styles.selectedPhotoContainer}>
            <img
              src={photos[selectedPhotoIndex].location}
              className={styles.selectedPhoto}
            />
            <div className={styles.arrowContainer}>
              <ChevronLeft
                className={styles.arrowButton}
                onClick={handlePrevPhoto}
              />
              <ChevronRight
                className={styles.arrowButton}
                onClick={handleNextPhoto}
              />
            </div>
          </div>
        </Modal>
      )}
      {showUploadModal && (
        <Modal
          cancelText='Cerrar'
          showActions={false}
          onCancel={closeUploadModal}
          showCloseIcon
        >
          <div className={styles.uploadPhotoSection}>
            <H3>Subir fotografía a la clase 📸</H3>
            <Paragraph className={styles.uploadDescriptionContainer}>
              Escanea el código QR para subir tus fotos desde el móvil. Si lo
              prefieres, también puedes cargar imágenes desde tu ordenador con
              un simple click en el botón.
            </Paragraph>
            <div className={styles.uploadButtonContainer}>
              <QRCode
                value={`${config.host}/upload-photo/${student.id}`}
                size={180}
                className={styles.qr}
              />
              <a href={`/upload-photo/${student.id}`} target='_blank'>
                <Button size='tiny'>Subir foto desde el ordenador</Button>
              </a>
            </div>
          </div>
        </Modal>
      )}
    </section>
  )
}

export default Gallery
