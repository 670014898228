import React, { useCallback, useEffect, useState } from 'react'
import { assocPath, groupBy, isEmpty, prop } from 'ramda'
import { SubscriptionConfigTypes } from 'utils/constants'
import { findSubscriptions } from 'api/subscriptions'
import { findBudgets } from 'api/budgets'
import useCustomNavigate from 'hooks/useCustomNavigate'
import Button from 'components/buttons/Button'
import { H2, H4, Paragraph } from 'components/typography'
import Drawer from 'components/layout/Drawer'
import Modal from 'components/modals/Modal'
import Radio from 'components/inputs/Radio'
import Slider from 'components/inputs/Slider'
import SubscriptionCard from 'components/cards/subscriptions/SubscriptionCard'
import RecommendedSubscriptionCard from 'components/cards/subscriptions/RecommendedSubscriptionCard'
import styles from './Subscriptions.module.css'
import RateEditor from 'components/subscriptions/RateEditor'

const recommendationToEdit = {
  subjects: {
    '615adcdcc4a627f8f8c032f9': 10,
    '615adcdcc4a627f8f8c032f6': 12
  }
}

function Subscriptions() {
  const [subscriptions, setSubscriptions] = useState([
    { id: 1, subscriptionType: 'agora' },
    { id: 2, subscriptionType: 'pack' }
  ])
  const [showModal, setShowModal] = useState(false)
  const [showDrawer, setShowDrawer] = useState(false)
  const [selectedSubscription, setSelectedSubscription] = useState({})
  const [recommendations, setRecommendations] = useState([
    {
      id: 2,
      subscriptionConfig: { name: 'Test' }
    }
  ])

  const navigate = useCustomNavigate()

  const toogleShowModal = () => setShowModal(state => !state)

  const toogleShowDrawer = () => setShowDrawer(state => !state)
  const handleSelectSubscription = subscription => {
    setSelectedSubscription(subscription)
    toogleShowDrawer()
  }

  const handleAccept = () => {}

  useEffect(() => {
    findSubscriptions({ projectExtraData: true })
      .then(setSubscriptions)
      .catch(err => {
        console.error('Error fetching subscriptions: ', err)
      })
  }, [])
  useEffect(() => {
    findBudgets({})
      .then(setRecommendations)
      .catch(err => {
        console.error('Error fetching recommendations: ', err)
      })
  }, [])

  const hasSuscriptions = subscriptions.length > 0
  const hasRecommendations = recommendations.length > 0
  const isPack = ({ subscriptionType }) =>
    [SubscriptionConfigTypes.pack, SubscriptionConfigTypes.extraHours].includes(
      subscriptionType
    )

  const packs = subscriptions.filter(isPack)
  const rates = subscriptions.filter(s => !isPack(s))

  return (
    <section className={styles.section}>
      {recommendationToEdit && !isEmpty(recommendationToEdit) ? (
        <div>
          <RateEditor rate={recommendationToEdit} onSave={toogleShowDrawer} />
        </div>
      ) : (
        <div className={styles.subscriptionSection}>
          <H2>{subscriptions.length > 1 ? 'Tus planes' : 'Tu plan'}</H2>
          {hasSuscriptions ? (
            <div className={styles.subscriptionList}>
              {subscriptions.map(subscription => (
                <SubscriptionCard
                  key={subscription.id}
                  subscription={subscription}
                  selected={selectedSubscription.id == subscription.id}
                  onClick={() => handleSelectSubscription(subscription)}
                />
              ))}
            </div>
          ) : (
            <Paragraph>Actualmente no tienes ningún plan contratado.</Paragraph>
          )}
        </div>
      )}
      {hasRecommendations && (
        <>
          <H2>Nuestra recomendación</H2>
          <div className={styles.subscriptionList}>
            {recommendations.map(recommendation => (
              <RecommendedSubscriptionCard
                key={recommendation.id}
                budget={recommendation}
                onBuy={() => navigate('/suscriptions/')}
              />
            ))}
          </div>
          <div className={styles.infoCard}>
            <H4>
              Si no te encaja ninguna de nuestras recomendaciones, no te
              preocupes porque tenemos más de 40 soluciones. Te ayudaremos a
              encontrar la tuya.
            </H4>
            <div className={styles.infoCardActions}>
              <Button label='Contactar con un experto' />
            </div>
          </div>
        </>
      )}
      {showModal && (
        <SubscriptionModal onOk={handleAccept} onCancel={toogleShowModal} />
      )}
      {showDrawer && (
        <Drawer onClose={() => handleSelectSubscription({})}>hola</Drawer>
      )}
    </section>
  )
}

function SubscriptionModal({ onOk, onCancel }) {
  const [shouldBeAddedToRate, setShouldBeAddedToRate] = useState(false)
  const [hours, setHours] = useState(0)
  return (
    <Modal showActions={false}>
      <div className={styles.contentModal}>
        <H2>{`¿Quieres contratar más horas de XYZ?`}</H2>
        <Paragraph>
          Estas horas se añadirán de forma puntual contratando un{' '}
          <span className={styles.bold}>Pack de Horas</span>.<br />
          <span className={styles.bold}>
            Si lo deseas puedes añadirlos a tu tarifa mensual.
          </span>
        </Paragraph>
        <div className={styles.columns}>
          <div className={styles.leftColumn}>
            <Button label='Matemáticas' type='accent-primary' size='tiny' />
            <Radio
              label='Quiero añadir a mi tarifa mensual'
              onCheck={setShouldBeAddedToRate}
              checked={shouldBeAddedToRate}
            />
          </div>
          <Slider value={hours} onChange={setHours} />
        </div>
        <div className={styles.actions}>
          <Button onClick={onOk} />
          <div />
          <Button
            label='Cancelar'
            size='small'
            type='accent-tertiary'
            onClick={onCancel}
          />
        </div>
      </div>
    </Modal>
  )
}

export default Subscriptions
