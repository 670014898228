//@ts-check
import React, { useEffect, useState } from 'react'
import { take, uniq } from 'ramda'
import { dateToString } from 'utils/date'
import defaultTeacherImg from 'assets/images/robot.png'
import Vector from 'assets/icons/Vector'
import Button from 'components/buttons/Button'
import styles from './VerticalTeacherCard.module.css'

/**
 * @typedef VerticalTecherCardClassNames
 * @property {string} container
 * @property {string} [innerContainer]
 * @property {string} [img]
 * @property {string} [name]
 * @property {string} [vector]
 * @property {string} [subjects]
 */
/**
 * @typedef VerticalTeacherCardProps
 * @property {VerticalTecherCardClassNames} classNames
 * @property {object} teacher
 * @property {object} subjectsById
 * @property {Date} date
 * @property {string} startHour
 * @property {boolean} disabled
 * @property {string} buttonLabel
 * @property {boolean} showButton
 * @property {import('components/buttons/Button').ButtonProps['size']} buttonSize
 * @property {() => void} onClick
 */

/**
 *
 * @param {Partial<VerticalTeacherCardProps>} props
 */
function VerticalTecherCard({
  classNames,
  teacher,
  subjectsById = {},
  date,
  startHour,
  disabled = false,
  buttonLabel = 'Empezar clase',
  showButton = true,
  buttonSize = 'small',
  onClick
}) {
  const imageUrl = teacher.profileUrl

  const [isValidImage, setIsValidImage] = useState(false)
  useEffect(() => {
    const img = new Image()
    img.onload = function () {
      setIsValidImage(true)
    }
    img.onerror = function () {
      setIsValidImage(false)
    }
    img.src = imageUrl
  }, [imageUrl])
  return (
    <div
      className={[
        styles.card,
        disabled ? styles.cardDisabled : '',
        classNames?.container || ''
      ].join(' ')}
    >
      {date && startHour && (
        <div className={styles.classDetails}>
          <p>{take(2, dateToString(date).split('/')).join(' / ')}</p>
          <div className={styles.verticalDivider} />
          <p>{startHour}</p>
        </div>
      )}
      <div
        className={[styles.innerContainer, classNames?.innerContainer].join(
          ' '
        )}
      >
        <img
          className={[styles.img, classNames?.img || ''].join(' ')}
          src={isValidImage ? imageUrl : defaultTeacherImg}
        />
        <p className={[styles.name, classNames?.name || ''].join(' ')}>
          {teacher.name}
        </p>
        <Vector
          className={[styles.vector, classNames?.vector || ''].join(' ')}
        />
        <div
          className={[styles.subjects, classNames?.subjects || ''].join(' ')}
        >
          {uniq(
            teacher.subjects?.slice(0, 4).reduce((acc = [], subjectId) => {
              const subject = subjectsById[subjectId]
              if (!subject) {
                console.log(
                  '---Check subjectId %s for teacher %s---',
                  subjectId,
                  teacher.id
                )
                return acc
              }
              acc.push(subject.name)
              return acc
            }, []) || []
          ).map((subjectName, index) => {
            return (
              <button key={index} className={styles.subject}>
                <span className={styles.subjectName}>{subjectName}</span>
              </button>
            )
          })}
        </div>
        {showButton && (
          <Button
            size={buttonSize}
            label={buttonLabel}
            type='tertiary'
            disabled={disabled}
            onClick={onClick}
          />
        )}
      </div>
    </div>
  )
}

export default VerticalTecherCard
