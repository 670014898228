import { GET, POST } from 'api'
import { makeCustomQueryAndProject } from 'utils/queries'

export const getMothlyOptionsBySubject = subjectId =>
  GET({
    url: 'students/calendar/mothly-options-by-subject/' + subjectId
  })
export const makeMothlyHoursByTeacher = (data = {}) =>
  POST({
    url: 'students/calendar/make-monthly-hours-by-teacher',
    data
  })

export const saveMonthlyOptions = (data = {}) =>
  POST({
    url: 'students/calendar/save-monthly-options',
    data
  })
