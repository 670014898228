import { useAuth } from 'context/AuthProvider'
import React, { useCallback, useEffect } from 'react'
import { HeaderProfile } from './Header'
import { noop } from 'utils'
import styles from './Drawer.module.css'

const MASK = 'mask'
function Drawer({ children, onClose = noop }) {
  const { student } = useAuth()
  const handleClose = useCallback(
    e => {
      if (e.target.id === MASK) onClose()
    },
    [onClose]
  )
  useEffect(() => {
    document.body.style.overflow = 'hidden'
    return () => {
      document.body.style.overflow = 'auto'
    }
  }, [])
  return (
    <div className={styles.drawer}>
      <div id={MASK} className={styles.mask} onClick={handleClose} />
      <div className={styles.content} onClick={handleClose}>
        <div className={styles.header}>
          <HeaderProfile student={student} />
        </div>
        {children}
      </div>
    </div>
  )
}

export default Drawer
