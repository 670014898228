//@ts-check
import React from 'react'
import { SubscriptionConfigTypes } from 'utils/constants'
import { noop } from 'utils'
import { H4, Paragraph } from 'components/typography'
import Button from 'components/buttons/Button'
import Tag from 'components/tags/Tag'
import styles from './SubscriptionCard.module.css'

//TODO:contratar??, new subscriptions configs?
function SubscriptionCard({
  subscription = {},
  selected = false,
  onClick = noop
}) {
  const isPack = [
    SubscriptionConfigTypes.pack,
    SubscriptionConfigTypes.extraHours
  ].includes(subscription.subscriptionType)

  const title = isPack ? 'Pack horas' : 'Suscripción mensual'
  const total = [subscription.paymentAmount, isPack ? '€' : '€/mes'].join('')
  return (
    <div className={[styles.card, selected ? styles.selected : ''].join(' ')}>
      <H4 className={styles.title}>{title}</H4>
      <div className={styles.subjects}>
        {[
          { name: 'Matemáticas', time: '4h/semana' },
          { name: 'Técnicas de estudio avanzadas nivel 2', time: '4h/semana' },
          { name: 'Matemáticas', time: '4h/semana' },
          { name: 'Ciencias sociales', time: '4h/semana' }
        ].map((subject, i) => {
          return (
            <div key={i} className={styles.subjectRow}>
              <Paragraph type='body2Bold' className={styles.subjectName}>
                {subject.name}
              </Paragraph>
              <Paragraph type='body2'>{subject.time}</Paragraph>
            </div>
          )
        })}
      </div>
      <div className={styles.timeTotal}>
        <div />
        <Tag label='20€/h' className={styles.tag} />
      </div>
      <div className={styles.divider} />
      <div className={styles.bottomSection}>
        <Paragraph className={styles.total}>{total}</Paragraph>
        <Button label='Modificar' onClick={onClick} />
      </div>
    </div>
  )
}

export default SubscriptionCard
